.footer {
    width: 100%;
    background-color: #40407a;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
    .logo {
        margin: 0;
        h2 {
            margin: 0;
        }
    }
    p { 
        color: #c6c6c6;
        margin: 0;
    }
}